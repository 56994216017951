import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { AgentInfoComponent } from './agent-info/agent-info.component';
import { AgentRoutingModule } from './agent-routing.module';
import { AgentAgreementModalComponent } from './modals/agent-agreement/agent-agreement.component';
import { AgentAuctionInfoGraphComponent } from './modals/agent-auction-info-modal/agent-auction-info-modal-graph/agent-auction-info-modal-graph.component';
import { AgentAuctionInfoModalComponent } from './modals/agent-auction-info-modal/agent-auction-info-modal.component';
import { AgentBankAccountsReminderModalComponent } from './modals/agent-bank-accounts-reminder-modal/agent-bank-accounts-reminder-modal.component';
import { AgentLookupModalComponent } from './modals/agent-lookup/agent-lookup.component';
import { AgentMarketOpportunitiesModalComponent } from './modals/agent-market-opportunities-modal/agent-market-opportunities-modal.component';
import { AgentReassignContractsModalComponent } from './modals/agent-reassign-contracts-modal/agent-reassign-contracts-modal.component';
import { AgentReactivationModalComponent } from './modals/agent-reactivation-modal/agent-reactivation-modal.component';

@NgModule({
	imports: [CommonModule, SharedModule, AgentRoutingModule],
	declarations: [
		AgentInfoComponent,
		AgentLookupModalComponent,
		AgentAgreementModalComponent,
		AgentAuctionInfoModalComponent,
		AgentAuctionInfoGraphComponent,
		AgentReassignContractsModalComponent,
		AgentMarketOpportunitiesModalComponent,
		AgentBankAccountsReminderModalComponent,
		AgentReactivationModalComponent,
	],
	exports: [
		AgentLookupModalComponent,
		AgentAgreementModalComponent,
		AgentAuctionInfoModalComponent,
		AgentAuctionInfoGraphComponent,
		AgentReassignContractsModalComponent,
		AgentMarketOpportunitiesModalComponent,
		AgentBankAccountsReminderModalComponent,
	],
})
export class AgentModule {}
