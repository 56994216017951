<div class="col home-page-container">
	<pk-broker-loader [show]="loading"></pk-broker-loader>
	<pk-broker-page-warnings [warnings]="warnings"></pk-broker-page-warnings>
	<ng-container *ngIf="!loading && loggedInUser && agentTree && agentTree.length">
		<div class="row mb-4" *ngFor="let agent of agentTree">
			<div class="col-md-12">
				<section class="clearfix">
					<div class="card add-box-styles">
						<div class="card-body">
							<h4 class="card-title d-inline-block">{{ agent.displayName }}</h4>
							<div class="toolbar-buttons float-right">
								<a
									*ngIf="
										loggedInUser.agentId === agent.agentId ||
										!loggedInUser.agentId ||
										!loggedInUser.agent?.parentId ||
										loggedInUser.agent?.isInHouseIndirectSale
									"
									[routerLink]="[environment.cPanel.agent.edit, agent.agentId]"
									class="btn btn-link py-0 px-2"
									container="body"
									tooltip="Click to edit agent">
									<fa-icon [icon]="['fas', 'pencil-alt']"></fa-icon> Edit
								</a>
							</div>
							<div class="row">
								<div class="col-sm-6 col-md-5"><label class="w-100 pb-2 border-bottom">Name</label></div>
								<div class="col-sm-6 col-md-7">
									<label class="w-100 pb-2 border-bottom value">{{ agent.displayName }}</label>
								</div>
								<div class="col-sm-6 col-md-5"><label class="w-100 pb-2 border-bottom">Contact</label></div>
								<div class="col-sm-6 col-md-7">
									<label class="w-100 pb-2 border-bottom value">{{ agent.getContactFullName() }}</label>
								</div>
								<div class="col-sm-6 col-md-5"><label class="w-100 pb-2 border-bottom">Email</label></div>
								<div class="col-sm-6 col-md-7 value">
									<label class="w-100 pb-2 border-bottom">
										<ng-container *ngIf="agent.email">
											<a [href]="'mailto:' + agent.email">{{ agent.email }}</a>
										</ng-container>
										<ng-container *ngIf="!agent.email">N/A</ng-container>
									</label>
								</div>
								<div class="col-sm-6 col-md-5"><label class="w-100 pb-2 border-bottom">Phone</label></div>
								<div class="col-sm-6 col-md-7 value">
									<label class="w-100 pb-2 border-bottom">
										<ng-container *ngIf="agent.phone">
											<a [href]="'tel:' + agent.phone">{{ agent.phone | phone: agent.phoneCountryId }}</a
											><span *ngIf="agent.ext">ext. {{ agent.phoneExt }}</span>
										</ng-container>
										<ng-container *ngIf="!agent.phone">N/A</ng-container>
									</label>
								</div>
								<div class="col-sm-6 col-md-5"><label class="w-100 pb-2 border-bottom">Mobile</label></div>
								<div class="col-sm-6 col-md-7 value">
									<label class="w-100 pb-2 border-bottom">
										<ng-container *ngIf="agent.mobile">
											<a [href]="'tel:' + agent.mobile">{{ agent.mobile | phone: agent.phoneCountryId }}</a>
										</ng-container>
										<ng-container *ngIf="!agent.mobile">N/A</ng-container>
									</label>
								</div>
								<ng-container *ngIf="!loggedInUser.isSaas">
									<div class="col-sm-6 col-md-5"><label class="w-100 pb-2 border-bottom">License Plan</label></div>
									<div class="col-sm-6 col-md-7 value">
										<label class="w-100 pb-2 border-bottom">
											{{ agent.getLicensePlan()?.name || 'N/A' }}
										</label>
									</div>
									<ng-container *ngIf="!agent.parentId">
										<div class="col-sm-6 col-md-5"><label class="w-100 h-100 pb-2 border-bottom">Add-Ons</label></div>
										<div class="col-sm-6 col-md-7 value">
											<label class="w-100 h-100 pb-2 border-bottom">
												<ng-container *ngFor="let addOn of agent.getAddOns()"> {{ addOn.name }}<br /> </ng-container>
												<ng-container *ngIf="!agent.getAddOns()?.length">N/A</ng-container>
											</label>
										</div>
										<div class="col-sm-6 col-md-5"><label class="w-100 pb-2 border-bottom">Failed Payment</label></div>
										<div class="col-sm-6 col-md-7 value">
											<label class="w-100 pb-2 border-bottom">
												{{ agent.hasFailedPayment ? 'Yes' : 'No' }}
											</label>
										</div>
									</ng-container>
									<ng-container *ngIf="isAdmin && agent.agentStripeCustomer?.stripeCustomerId">
										<div class="col-sm-6 col-md-5"><label class="w-100 pb-2 border-bottom">Stripe Customer</label></div>
										<div class="col-sm-6 col-md-7 value">
											<label class="w-100 pb-2 border-bottom">
												<a target="_blank" [href]="stripeCustomerDashboard + agent.agentStripeCustomer.stripeCustomerId"
													>Go To Stripe Customer Account <fa-icon [icon]="['fas', 'external-link-alt']"></fa-icon
												></a>
											</label>
										</div>
									</ng-container>
								</ng-container>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
		<div class="row mb-4">
			<div class="col-md-12">
				<section class="clearfix">
					<div class="card add-box-styles">
						<div class="card-body">
							<h4 class="card-title d-inline-block">Logins</h4>
							<div class="toolbar-buttons float-right">
								<a
									*ngIf="loggedInUser.isAdmin || loggedInUser.isOwner || (!agent.isSaas && loggedInUser.isRole('sales manager'))"
									[routerLink]="[
										environment.cPanel.users.add,
										agent.parentId && (!agent.isSaas || agent.brotherId) ? 'sales' : 'sales manager',
										agent.agentId,
									]"
									class="btn btn-link py-0 px-2"
									container="body"
									tooltip="Click to add a login">
									<fa-icon [icon]="['fas', 'plus']"></fa-icon> Add
								</a>
							</div>
							<div class="table-responsive">
								<table class="table table-sm table-bordered table-striped table-hover">
									<thead>
										<tr>
											<th>Username</th>
											<th>Last Login</th>
											<th>Add Date</th>
											<th>Active</th>
											<th>Primary</th>
											<th>Locked</th>
											<th>Password Expired</th>
											<th class="text-center"><span *ngIf="loggedInUser.hasPermission('user-admin')">Actions</span></th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let login of agent.logins | filter: 'agentId' : agent.agentId">
											<td>{{ login.username }}</td>
											<td>
												<span [ngClass]="{ 'label label-warning': login.getDisplayLastLogin() === 'Never' }">
													{{ login.getDisplayLastLogin() }}
												</span>
											</td>
											<td>{{ login.addDate | date: environment.dateFormat }}</td>
											<td><fa-icon [icon]="['fas', 'check']" *ngIf="login.isActive"></fa-icon></td>
											<td><fa-icon [icon]="['fas', 'check']" *ngIf="login.isPrimary"></fa-icon></td>
											<td><fa-icon [icon]="['fas', 'check']" *ngIf="login.isLocked"></fa-icon></td>
											<td><fa-icon [icon]="['fas', 'check']" *ngIf="login.isPasswordExpired"></fa-icon></td>
											<td>
												<a
													[routerLink]="[
														environment.cPanel.users.edit,
														agent.parentId && (!agent.isSaas || agent.brotherId) ? 'sales' : 'sales manager',
														agentTree[0].agentId,
														login.userId,
													]"
													class="btn btn-link py-0 px-2"
													container="body"
													tooltip="Click to edit"
													[adaptivePosition]="false">
													<fa-icon [icon]="['fas', 'edit']"></fa-icon>
												</a>
												<a
													*ngIf="loggedInUser.isAdmin && !login.impersonationLoading"
													href="javascript:void(0)"
													(click)="impersonateUser(login)"
													class="btn btn-link py-0 px-2"
													container="body"
													tooltip="Click to impersonate user"
													[adaptivePosition]="false"
													><fa-icon [icon]="['fas', 'user-secret']"> </fa-icon>
												</a>
												<a *ngIf="login.impersonationLoading"><fa-icon [icon]="['fas', 'spinner']" [spin]="true" size="lg"></fa-icon></a>
												<a
													*ngIf="loggedInUser.isAdmin && !login.sendingWelcomeEmail"
													href="javascript:void(0)"
													(click)="sendWelcomeEmail(login)"
													class="btn btn-link py-0 px-2"
													container="body"
													tooltip="Click to send the welcome email"
													[adaptivePosition]="false">
													<fa-icon [icon]="['fas', 'paper-plane']"></fa-icon>
												</a>
												<a *ngIf="login.sendingWelcomeEmail"><fa-icon [icon]="['fas', 'spinner']" [spin]="true" size="lg"></fa-icon></a>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</ng-container>
</div>
