import { Component, OnInit } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { GraphqlService } from 'src/app/graphql/graphql.service';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';

@Component({
	selector: 'pk-broker-agent-market-opportunities-modal',
	templateUrl: './agent-market-opportunities-modal.component.html',
	styleUrls: ['./agent-market-opportunities-modal.component.scss'],
})
export class AgentMarketOpportunitiesModalComponent extends AbstractPageForm implements OnInit {
	public marketOpportunities = [
		{ utilityName: 'Ameren', stateShort: 'IL' },
		{ utilityName: 'ComEd', stateShort: 'IL' },
		{ utilityName: 'Eversource', stateShort: 'MA' },
		{ utilityName: 'PPL', stateShort: 'PA' },
		{ utilityName: 'PennPower', stateShort: 'PA' },
		{ utilityName: 'PENELEC', stateShort: 'PA' },
		{ utilityName: 'West Penn Power', stateShort: 'PA' },
	];
	private agentMarketOpportunities: { utilityName: string; stateShort: string }[];

	constructor(
		private activeModal: BsModalRef,
		private graphqlService: GraphqlService,
	) {
		super();
	}

	ngOnInit(): void {
		this.getAgentMarketOpportunities();
	}

	private async getAgentMarketOpportunities(): Promise<void> {
		const result = await this.graphqlService.getAgentMarketOpportunities(this.loggedInUser.agentId);

		this.agentMarketOpportunities = result.data.agent.marketOpportunities;
		this.loading = false;
	}

	public hasMarket(marketOpportunity: { utilityName: string; stateShort: string }): boolean {
		return this.agentMarketOpportunities.some(
			a => a.utilityName.toLowerCase().includes(marketOpportunity.utilityName.toLowerCase()) && a.stateShort === marketOpportunity.stateShort,
		);
	}

	public close(): void {
		this.activeModal.hide();
	}
}
