import { AfterViewChecked, Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { Agent, AgentAdvanced } from 'src/app/shared/models';
import { AbstractPageForm } from '../../../shared/pages/page-form.abstract';
import { AgentSearchService } from '../../services/agent-search.service';

@Component({
	selector: 'pk-broker-agent-reassign-contracts-modal',
	templateUrl: './agent-reassign-contracts-modal.component.html',
	styleUrls: ['./agent-reassign-contracts-modal.component.scss'],
	providers: [AgentSearchService],
})
export class AgentReassignContractsModalComponent extends AbstractPageForm implements AfterViewChecked {
	// inputs
	public oldAgent: Agent;

	public agents: AgentAdvanced[];

	public onSubmit: Subject<{ oldAgent: Agent; newAgent: Agent }> = new Subject();

	constructor(
		public activeModal: BsModalRef,
		private fb: FormBuilder,
		private agentSearchService: AgentSearchService,
	) {
		super();
		this.submitAudit = 'cpanel-agents-reassing-contrats-modal:submit';
	}

	ngAfterViewChecked(): void {
		const emptyRows = document.getElementsByClassName('dx-freespace-row');
		Array.from(emptyRows).forEach(node => node.remove());
	}

	public async loadPageData(): Promise<void> {
		this.agentSearchService.buildSearchForm();
	}

	public getForm() {
		return this.fb.group({
			oldAgent: this.oldAgent,
			oldAgentName: this.fb.control({ value: `${this.oldAgent.displayName} [${this.oldAgent.email}]`, disabled: true }),
			newAgent: this.fb.control({ value: null as Agent, disabled: true }, [Validators.required]),
			newAgentName: '',
		});
	}

	public async onFormLoaded(): Promise<void> {
		this.agentSearchService.setupAgentGridCustomStore();
	}

	protected async onFormSubmitted() {
		this.onSubmit.next({ oldAgent: this.form.oldAgent.value, newAgent: this.form.newAgent.value });
		this.activeModal.hide();
	}

	get searchForm() {
		return this.agentSearchService.searchForm;
	}
	get agentsDataSource() {
		return this.agentSearchService.agentsDataSource;
	}
	get search() {
		return this.agentSearchService.search;
	}
	get dataGrid() {
		return this.agentSearchService.dataGrid;
	}
	get activeSelections() {
		return this.agentSearchService.activeSelections;
	}

	public onAgentsDataGridInit(event: any): void {
		this.agentSearchService.setupAgentDataGridFromGridInitEvent(event);
	}

	public selectAgent(agent: Agent): void {
		this.form.newAgent.setValue(agent);
		this.form.newAgentName.setValue(`${agent.displayName} [${agent.email}]`);
	}

	public onSearch(): void {
		if (this.search.value.length > 3 || !this.search.value) {
			const state = this.dataGrid.state();
			this.dataGrid.state(state);
		}
	}
}
