<pk-broker-modal [showHeaderCloseButton]="false">
	<ng-template #headerTemplate>
		<h5 class="modal-title">Agent Terms of Service Agreement</h5>
	</ng-template>
	<ng-template #bodyTemplate>
		<pk-broker-loader [show]="loading"></pk-broker-loader>
		<ng-container *ngIf="!loading">
			<div class="container-fluid">
				<p>
					<strong>We need you to agree to {{ loggedInUser.showTermsOfService && loggedInUser.showCompliance ? 'two things' : 'one thing' }}:</strong>
				</p>
				<ng-container *ngIf="loggedInUser.showTermsOfService">
					<h3 class="pt-3">1. Our Terms & Conditions</h3>
					<p>
						Please open and read our Terms and Conditions, type out your e-signature (first and last name), then click the "Agree" button to submit
						your signature.
					</p>
					<div class="row mb-3">
						<div class="col-6 offset-3 text-center">
							<a
								class="btn btn-link px-4"
								[href]="environment.baseUrl + '/attachments/' + attachmentId + '/view?access_token=' + environment.apiKey"
								target="_blank">
								<fa-icon [icon]="['far', 'file-pdf']"></fa-icon> Open Terms and Conditions
							</a>
						</div>
					</div>
					<div class="row">
						<div class="col-6 text-center">
							<div class="form-element-container" [ngClass]="{ invalid: showSignatureWarning }">
								<input placeholder="Signature (First/Last Name)" type="text" id="signature" class="form-control" [(ngModel)]="signature" />
							</div>
							<p class="invalid-feedback" *ngIf="showSignatureWarning">Please fill out your signature to agree to the Terms & Conditions.</p>
						</div>
						<div class="col-6 pull-right text-right">
							<button class="btn btn-light pull-right" [ngClass]="{ active: tosAgreed && signature }" (click)="agreeTos()">
								Agree <fa-icon *ngIf="tosAgreed && signature" [classes]="['text-success', 'ml-1']" [icon]="['fas', 'check']"></fa-icon>
							</button>
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="loggedInUser.showCompliance">
					<h3 class="pt-3">{{ loggedInUser.showTermsOfService ? '2.' : '1.' }} Out Compliance Do's and Don'ts</h3>
					<p>Please read over our Compliance Do's and Don'ts, then click the "Agree" button below to agree.</p>
					<hr />
					<ul class="large-bullets">
						<li>
							All customers must have a valid email address. Examples of unacceptable email addresses include: reply&#64; or your own email address.
						</li>
						<li>All customers must have a phone number that is not your own.</li>
						<li>
							The customer must be the one to receive and sign the contract. You may not sign or approve the contract on behalf of the customer.
						</li>
						<li>All aspects of the contract must be explained to the customer prior to signing (product type, term, rate, supplier, etc.).</li>
						<li>The customer needs to be aware of their current rate.</li>
						<li>
							Any customer who is under current contract with a supplier may be subject to an Early Termination Fee if another contract is signed
							before the prior contract expires.
						</li>
						<li>
							A renewal customer must be renewed through the renewal link that will be sent out to them 30 days prior to their expiration date. These
							customers should not be entered again through the Turn-Key site.
						</li>
					</ul>
					<p>In addition to the rules above, pertaining to residential sign-ups:</p>
					<ul class="large-bullets">
						<li>The customer must be the one to receive and confirm their enrollment.</li>
					</ul>
					<div class="row">
						<div class="col-6"></div>
						<div class="col-6 pull-right text-right">
							<button class="btn btn-light pull-right" [ngClass]="{ active: complianceAgreed }" (click)="agreeCompliance()">
								Agree <fa-icon *ngIf="complianceAgreed" [classes]="['text-success', 'ml-1']" [icon]="['fas', 'check']"></fa-icon>
							</button>
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="loggedInUser.showIdDocument">
					<h3 class="pt-3">3. Upload your ID.</h3>
					<p>Please upload your proof of identification for verification purposes.</p>
					<div class="row">
						<div class="col">
							<div class="form-element-container">
								<div class="custom-file">
									<input type="file" class="custom-file-input" id="documentFile" (change)="onHandleFile($event)" />
									<label class="custom-file-label" for="documentFile">{{ idDocumentFile ? idDocumentFile.name : 'Please choose a file...' }}</label>
								</div>
							</div>
							<div class="alert alert-warning" *ngIf="fileWarning">Please upload a .png, .jpg, .jpeg, .webp, or .heic file.</div>
						</div>
					</div>
				</ng-container>
			</div>
			<pk-broker-loader [show]="saving"></pk-broker-loader>
		</ng-container>
	</ng-template>
</pk-broker-modal>
