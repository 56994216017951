import { Component, OnInit } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { AttachmentService } from 'src/app/attachment/attachment.service';
import { HelperService } from 'src/app/shared/helper.service';
import { AbstractPageForm } from 'src/app/shared/pages/page-form.abstract';
import { GraphqlService } from '../../../graphql/graphql.service';
import { User } from '../../../shared/models';

@Component({
	selector: 'pk-broker-agent-agreement',
	templateUrl: './agent-agreement.component.html',
	styleUrls: ['./agent-agreement.component.scss'],
})
export class AgentAgreementModalComponent extends AbstractPageForm implements OnInit {
	public attachmentId: string;

	public saving = false;

	private tosAgreed = false;
	private complianceAgreed = false;
	private idDocumentUploaded = false;
	public signature: string;
	public idDocumentFile: File;
	public idDocumentAttachmentId: string;
	public fileWarning: string;
	public showSignatureWarning = false;

	constructor(
		private activeModal: BsModalRef,
		private helperService: HelperService,
		private attachmentService: AttachmentService,
		private toastrService: ToastrService,
		private graphqlService: GraphqlService,
	) {
		super();
	}

	ngOnInit(): void {
		this.getTermsOfService();
		this.tosAgreed = !this.loggedInUser.showTermsOfService;
		this.complianceAgreed = !this.loggedInUser.showCompliance;
		this.idDocumentUploaded = !this.loggedInUser.showIdDocument;
	}

	private async getTermsOfService(): Promise<void> {
		const result = await this.graphqlService.getTermsOfService();
		if (result.data.company.termsOfServiceAttachment) {
			this.attachmentId = result.data.company.termsOfServiceAttachment.attachmentId;
		}
		this.loading = false;
	}

	public async agreeTos(): Promise<void> {
		this.resetWarnings();
		if (this.signature) {
			this.tosAgreed = true;
		} else {
			this.showSignatureWarning = true;
		}
		if (this.complianceAgreed && this.signature && this.idDocumentUploaded) {
			await this.submit();
		}
	}

	public async agreeCompliance(): Promise<void> {
		this.resetWarnings();
		this.complianceAgreed = true;
		if (this.tosAgreed && this.idDocumentUploaded) {
			await this.submit();
		}
	}

	public async onHandleFile(event: any): Promise<void> {
		this.resetWarnings();
		this.idDocumentUploaded = true;
		const files = this.helperService.getFilesFromEventTarget(event);
		this.idDocumentFile = files[0];
		if (this.tosAgreed && this.complianceAgreed) {
			await this.submit();
		}
	}

	private resetWarnings(): void {
		this.fileWarning = null;
		this.showSignatureWarning = false;
	}

	public async submit(): Promise<void> {
		this.saving = true;
		try {
			if (this.idDocumentFile) {
				const formData = new FormData();
				formData.append('files', this.idDocumentFile, this.idDocumentFile.name);
				formData.append('attachmentTypeId', this.CONSTANTS.attachmentTypes.id);
				formData.append('description', this.idDocumentFile.name);
				formData.append('agentId', this.loggedInUser.agentId);
				const attachmentResult = await this.attachmentService.upload(formData);
				this.idDocumentAttachmentId = attachmentResult.message[0].attachmentId;
			}
			const userDto = {
				showTermsOfService: false,
				showCompliance: false,
				showIdDocument: false,
				signature: this.signature,
				idDocumentAttachmentId: this.idDocumentAttachmentId,
			} as User;
			Object.assign(this.loggedInUser, userDto);
			await this.graphqlService.updateUser(this.loggedInUser.userId, userDto);
			await this.securityService.updateAuthFieldsUser(this.loggedInUser);
			this.toastrService.success('You have successfully agreed to the terms of service!', 'Terms of Service');
			this.activeModal.hide();
		} catch (e) {
			const message = this.helperService.getErrorMessage(e).join(';');
			if (message.includes('Unsupported File Extension')) {
				this.fileWarning = message;
			} else {
				this.toastrService.warning(
					'There was a problem saving your request. We have been notified and are working to fix the issue. ' +
						'Please check back again in 30 minutes.',
					'Terms of Service',
				);
			}
		}
		this.saving = false;
	}
}
