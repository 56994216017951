import { Component } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'pk-broker-agent-reactivation-modal',
	templateUrl: './agent-reactivation-modal.component.html',
	styleUrls: ['./agent-reactivation-modal.component.scss'],
})
export class AgentReactivationModalComponent {
	constructor(private activeModal: BsModalRef) {}

	public close(): void {
		this.activeModal.hide();
	}
}
