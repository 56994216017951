import { Component } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { confirm } from 'devextreme/ui/dialog';

import { GraphqlService } from '../../graphql/graphql.service';
import { HelperService } from '../../shared/helper.service';
import { Agent, Breadcrumb, User } from '../../shared/models';
import { AbstractPage } from '../../shared/pages/page.abstract';

@Component({
	selector: 'pk-broker-agent-info',
	templateUrl: './agent-info.component.html',
	styleUrls: ['./agent-info.component.scss'],
})
export class AgentInfoComponent extends AbstractPage {
	public static breadcrumbs = [
		{
			text: 'Dashboard',
			route: '/contract',
		},
		{
			text: 'Agent Information',
			isLast: true,
		},
	] as Breadcrumb[];

	public agent: Agent;
	public agentTree: Agent[] = [];

	public window = window;

	constructor(
		private route: ActivatedRoute,
		private graphqlService: GraphqlService,
	) {
		super(AgentInfoComponent.breadcrumbs, 'Agent Information');
		this.route.paramMap.subscribe(async (paramMap: ParamMap) => {
			const agentId = paramMap.get('agentId');

			try {
				const result = await this.graphqlService.getAgentInfoData(agentId);
				this.agent = new Agent(result.data.agent);
				this.agentTree.push(this.agent);
				await this.getAgentParent(this.agent);
			} catch (e) {
				this.warnings = HelperService.getErrorMessage(e);
			}

			this.loading = false;
		});
	}

	public async getAgentParent(agent: Agent): Promise<void> {
		if (agent.parentId) {
			try {
				const result = await this.graphqlService.getParentAgentInfoData(agent.parentId);
				agent.parent = new Agent(result.data.agent);
				this.agentTree.push(agent.parent);
				await this.getAgentParent(agent.parent);
			} catch (e) {
				this.warnings = HelperService.getErrorMessage(e);
			}
		}
	}

	get isAdmin(): boolean {
		return this.loggedInUser.isAdmin;
	}
	get isAdminOrOwner(): boolean {
		return this.isAdmin || this.loggedInUser.isOwner;
	}
	get stripeCustomerDashboard(): string {
		let baseUrl = 'https://dashboard.stripe.com/';
		if (this.environment.name !== 'production') {
			baseUrl += 'test/';
		}

		return baseUrl + 'customers/';
	}

	public impersonateUser(login: User): void {
		this.securityService.impersonateUser(login);
	}

	public async sendWelcomeEmail(login: User): Promise<void> {
		const result = await confirm('Are you sure you want to send this user the welcome email?  The password will be reset.', 'Confirm');
		if (result) {
			login['sendingWelcomeEmail'] = true;
			await this.graphqlService.sendWelcomeEmail(login.userId);
			login['sendingWelcomeEmail'] = false;
		}
	}
}
